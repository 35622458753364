import axios from "axios";

let sendSMS = function(phone, message) {
    return new Promise((resolve, reject) => {
        let sendMessageUrl = "https://mthrsyjjii.execute-api.us-east-1.amazonaws.com/prod";
        let url = `${sendMessageUrl}?phone=${phone}&message=${message}`;

        axios
            .get(url)
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

export default { sendSMS };
